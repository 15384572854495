/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, Link } from "react-router-dom";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { MappedEnvironmentV2 } from "../types";
import { OrganizationalUnitCell } from "./OrganizationalUnitCell";

type Props = {
  applicationId: string;
  environment: MappedEnvironmentV2;
};

export function EnvironmentRow({ applicationId, environment }: Props) {
  return (
    <Table.tr>
      <Table.td>
        <Link
          to={generatePath(ROUTES.applications.environment, {
            application: applicationId,
            environment: environment.id,
          })}
        >
          {environment.name}
        </Link>
      </Table.td>
      <Table.td>
        <OrganizationalUnitCell orgUnits={environment.orgUnits} />
      </Table.td>
      <Table.td>
        <Text
          mb="0"
          color={
            environment.savingsSuggestion !== "-"
              ? theme.color.text.success
              : undefined
          }
        >
          {environment.savingsSuggestion}
        </Text>
      </Table.td>
      <Table.td>{environment.cost}</Table.td>
      <Table.td>{environment.forecast}</Table.td>
      <Table.td>{environment.budget}</Table.td>
      <Table.td>
        <FlexContainer columnGap={theme.spacing.spacing01}>
          <ExtendedTooltip caption="Edit Environment">
            <Button
              disabled
              type="button"
              icon="edit"
              severity="low"
              size="md"
              onClick={noop}
            />
          </ExtendedTooltip>
          <Button
            disabled
            type="button"
            icon="trash"
            severity="low"
            size="md"
            onClick={noop}
          />
        </FlexContainer>
      </Table.td>
    </Table.tr>
  );
}
